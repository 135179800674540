import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Common/Layout/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Common/Hero/Hero';
import ServicesBlock from '../components/ServicesBlock/ServicesBlock';

const IndexPage = () => (
  <Layout>
    <Seo
      title="Leaders in Property Management Northern Ontario"
      description="Apartment, condo and commercial rental listings in Sudbury, Ontario."
    />
    <Hero
      background={
        <StaticImage
          src="../images/large-hero-bg.jpg"
          layout="fullWidth"
          placeholder="blurred"
          quality="90"
          alt=""
        />
      }
    >
      <h1 className="h-home-hero-title">
        A proven track record of professionally managed residential and
        commercial properties
      </h1>
      <p className="mt-4">
        Luxor Management has been a leader in the property management business,
        since 1977.
      </p>
      <p className="mt-6">
        We manage over 1,325 residential units consisting of a mix of
        townhouses, high-rise, mixed-use and non-profit projects. And over
        1,000,000 square feet of commercial properties consisting of strip malls,
        office buildings, shopping malls and medical centres.
      </p>
    </Hero>
    <ServicesBlock />
  </Layout>
);

export default IndexPage;
