/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from 'react';

import buildings from '../../../data/buildings.json';
import Select from '../Input/Select';

const BuildingSelector = ({ ...props }) => {
  const [buildingsList, setBuildingsList] = useState();

  const listBuildings = (filterByName) =>
    buildings.buildings.reduce((filtered, data) => {
      if (data.cityName === filterByName) {
        filtered.push(
          <option key={data.name} value={data.name}>
            {data.name}
          </option>,
        );
      }
      return filtered;
    }, []);

  // buildings.buildings.filter((building) => building.cityName === filterByName);

  return (
    <>
      <Select
        horizontal
        asterisk={props.asterisks}
        className="mb-5"
        label="City"
        name="City"
        placeholder="Select a city"
        onChange={(e) => setBuildingsList(listBuildings(e.target.value))}
      >
        <option value="Brooks, AB">Brooks, AB</option>
        <option value="Coniston">Coniston</option>
        <option value="Dowling">Dowling</option>
        <option value="Elliot Lake">Elliot Lake</option>
        <option value="North Bay">North Bay</option>
        <option value="Ottawa">Ottawa</option>
        <option value="Sudbury">Sudbury</option>
      </Select>
      <Select
        horizontal
        asterisk={props.asterisks}
        className="mb-5"
        label="Building"
        name="Building"
        placeholder="Select building address"
      >
        {buildingsList}
      </Select>
    </>
  );
};

export default BuildingSelector;
